.App {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
}

.content {
  flex: 1;
  overflow: scroll;
}

.icon {
  margin: 5px;
}

.icon:hover {
  cursor: pointer;
}

.dark {
  background-color: #18181a;
}

.dark h1,
.dark h2,
.dark h3,
.dark h4,
.dark h5,
.dark h6,
.dark p,
.dark label {
  color: white;
}

.dark hr {
  border-top: 1px solid #495057;
}

.alert p {
  color: black !important;
}
